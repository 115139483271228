import gql from 'graphql-tag'

export const BlogPage = gql`
  fragment BlogPage on NewBlogPageType {
    id
    author
    detailUrl(cohort: $cohort)
    preview
    previewTruncated
    slug
    title
    dateDict {
      day
      month
      monthStr
      year
    }
    blogCategories {
      id
      title
      slug
    }
    headerImage {
      id
      image
    }
  }
`
